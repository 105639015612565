<!-- Finder expansible de Expedientes Traslados -->

<template>
  <div class="exp_M_traslado_XM" v-if="schema">      
    <v-sheet :elevation="4">

      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left">
        <!-- Mto -->            
        <btramto          
          :btra_permisos="schema.btra"
          :btra_particular="Entorno.btra"
          btra_modulo="xm"
          :estado="estado"
          @onEvent="event_btra_Mto">                
        </btramto>     
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">                                  
        <v-row no-gutters>
          <v-col cols="6">
            <uiDate 
              v-model="schema.ctrls.salidafhhr.value"
              :label="schema.ctrls.salidafhhr.label"          
              type="datetime-local"
              :disabled="!is_edit">          
            </uiDate> 

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.procedencia.value"
              :label="schema.ctrls.procedencia.label"
              :disabled="!is_edit">            
            </v-text-field>

            <ctrlfinder
              finderName="atlas_F"
              :schema="schema.ctrls.lugar_procedencia"
              :edicion="is_edit">            
            </ctrlfinder>
          </v-col>

          <v-col cols="6">          
            <uiDate 
              v-model="schema.ctrls.llegadafhhr.value"
              :label="schema.ctrls.llegadafhhr.label"          
              type="datetime-local"
              :disabled="!is_edit">          
            </uiDate>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.destino.value"
              :label="schema.ctrls.destino.label"
              :disabled="!is_edit">            
            </v-text-field>

            <ctrlfinder
              finderName="atlas_F"
              :schema="schema.ctrls.lugar_destino"
              :edicion="is_edit">            
            </ctrlfinder>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="4">
            <v-select
              v-bind="$select"
              v-model="schema.ctrls.medio.value"
              :label="schema.ctrls.medio.label"
              :items="get_medio"
              :disabled="!is_edit"
              item-value="name"
              item-text="name">            
            </v-select>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.vuelo.value"
              :label="schema.ctrls.vuelo.label"
              :disabled="!is_edit">            
            </v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.lta.value"
              :label="schema.ctrls.lta.label"
              :disabled="!is_edit">            
            </v-text-field>
          </v-col>
        </v-row>

        <ctrlobs3          
          v-if="record"
          :schemaComponente="schema.ctrls.obs"
          :schema="schema"
          :record="record"
          :edicion="is_edit">
        </ctrlobs3>       
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";  
  import plugs from "@/common/general_plugs";  
  const btramto = () => plugs.groute("btramto.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");  
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  
  export default {
    mixins: [mixinMto],
    components: { btramto, ctrlfinder, ctrlobs3 },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'exp_Tras_M',
        stName:'stMexp_Tras_M',
        disparoFinder:false,
        
        // Pendiente: ver donde ponemos campo_relacionado
        campo_relacionado: "id_exp",
      };
    },  

    computed: {
      // devuelvo array con los medios de transporte
      get_medio() {
        return [
          { id: "0", name: "TERRESTRE" },
          { id: "1", name: "AEREO" },
          { id: "2", name: "MARÍTIMO" }
        ];
      },
    },

    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;    
        this.Entorno.btra="XM";    
      },


    }
};
</script>
